.<template>
  <b-form-group label="Files upload (max 4Mb)" label-for="title">
    <div class="d-flex">
      <b-form-file
        v-model="current_files"
        ref="file-input"
        @input="addCurrentFiles"
        multiple
      ></b-form-file>
      <!-- <b-button
        size="sm"
        class="ml-3 flex-shrink-0"
        @click="addCurrentFiles"
        :disabled="!this.current_files.length"
        >Add file</b-button
      > -->
    </div>
    <div v-if="files.length" class="files-wrapper mt-3">
      <p>Files list</p>
      <div
        class="row align-items-center my-2"
        v-for="(file, index) in files"
        :key="file.url"
      >
        <div class="col-8">
          <a :href="file.url" target="_blank" class="mr-2"><b-icon-link /></a>
          <span>{{ file.nome }}</span>
        </div>
        <div class="col-4 d-flex justify-content-between">
          <span>
            <b-badge v-if="file.toUpload" variant="primary">TO UPLOAD</b-badge>
            <b-badge v-else-if="file.toDelete" variant="danger"
              >TO DELETE</b-badge
            >
            <b-badge v-else variant="success">UPLOADED</b-badge>
          </span>
          <span>
            <b-button
              v-if="file.toUpload"
              size="sm"
              @click="removeFromList(index)"
              >Remove</b-button
            >
            <b-button
              v-else-if="file.toDelete"
              size="sm"
              @click="setDeleteFlag(file, false)"
              >Don't delete</b-button
            >
            <b-button v-else size="sm" @click="setDeleteFlag(file, true)"
              >Delete</b-button
            >
          </span>
        </div>
      </div>
    </div>
  </b-form-group>
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {
      files: [],
      current_files: [],
    };
  },
  mounted() {
    this.files = this.value;
  },
  methods: {
    addCurrentFiles() {
      this.current_files.forEach((x) => {
        let newFile = {
          file: x,
          url: URL.createObjectURL(x),
          nome: x.name,
          toUpload: true,
        };
        this.files.push(newFile);
      });
      this.current_files = [];
      this.$emit("input", this.files);
    },
    setDeleteFlag(file, val) {
      this.$set(file, "toDelete", val);
      this.$emit("input", this.files);
    },
    removeFromList(index) {
      this.files.splice(index, 1);
      this.$emit("input", this.files);
    },
  },
  watch: {
    value: {
      handler(newVal) {
        this.files = newVal;
      },
      deep: true,
    },
  },
};
</script>